@import "../../../styles/variables";

.wrapper {
  position: relative;
  height: auto;
  background-color: #F5F5F5;
  border-radius: 4px;

  &.isDelayed {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 6px;
      background: #F95858;
      border-radius: 4px 0 0 4px;
    }
  }
  &.hasChild {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 6px;
      background: $gray100;
      border-radius: 4px 0 0 4px;
    }
  }
}

.firstColumn {
  width: 100%;
  background-color: #EFEFEF;
  border-radius: 4px 0 0 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 13px 13px 14px;

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
}

.tooltipStatus {
  height: max-content;
}

.status {
  display: inline-block;
  margin: 0;
  position: relative;
  z-index: 1;
}

.secondColumn {
  width: 100%;
  padding: 16px 13px 8px;
}

.tags {
  margin-top: 4px;
  display: flex;
  gap: 6px;
}

.tag {
  display: inline-block;
  width: max-content;
  color: $white;
  border-radius: 2px;
  padding: 1px 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  &.tagCompletion {
    background-color: $green;

    &.red {
      background-color: $red;
    }
  }

}

.sprint {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $blue;
  margin-bottom: 4px;
}

.text {
  display: flex;
  position: relative;
}

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;

  span {
    color: $gray300;
  }

  &.withoutStatus {
    transform: translateX(8px);
  }
}

.date {
  text-align: center;
}

.date,
.difficulty {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.date.delayed:not(:empty) {
  border-bottom: 1px solid $red;
}
